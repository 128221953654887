<template>
  <div :style="{'margin':'0 auto','width':'100px','height':`${height}px`,'padding-top':`${paddingTop}px`,'box-sizing':'border-box'}">
    <img src="@/assets/nodata.png" alt="">
    <p style="text-align:center;color:#666">暂无数据</p>
  </div>
</template>

<script>
  export default {
    props: {
      height: {
        type: String,
        default: '300'
      },
      paddingTop: {
        type: String,
        default: '100'
      },
    }
  }
</script>

<style>

</style>