<template>
  <div class="wrap">
    <div class="filter-content">
      <div class="status-tab">
        <div class="status-tab-item" :class="{active: isActive === 'starting'}" @click="selectStatus('starting')">正在进行中</div>
        <div class="status-tab-item" :class="{active: isActive === 'notStart'}" @click="selectStatus('notStart')">即将开始</div>
        <div class="status-tab-item" :class="{active: isActive === 'ended'}" @click="selectStatus('ended')">已结束</div>
      </div>
    </div>

    <div class="container">
			<NoDataFound v-if="tenderList.length == 0"></NoDataFound>

			<div class="tender-box" v-else>
				<router-link
					class="tender-item"
          :to="'/home/TenderDetails?id=' + item.id"
					v-for="(item, ind) in tenderList"
					:key="item.id"
          tag="div">
					<div class="header">
						<div class="title">
							<span class="ranking">No.{{ind >= 9? ind+1 : '0'+(ind+1)}}</span>
							<p class="name">{{ item.bidName }}</p>
						</div>
						<div class="price" :class="[isActive === 'starting'? 'price1' : isActive === 'notStart'? 'price2' : 'price3']">{{item.bidPrice}}</div>
					</div>

					<div class="main">
						<div class="list">
              <div class="list-item title">
								<div class="num"></div>
								<div class="name">产品名称</div>
								<div class="spec">采购量</div>
								<div class="price">单价</div>
                <div class="totle">产品总价</div>
							</div>

							<!--<div class="list-item" v-for="index in 3" >-->
								<!--<div class="num">{{index}}</div>-->
								<!--<div class="name">{{item.productList[index-1]}}</div>-->
								<!--<div class="spec">{{item.productList[index].number}}{{item.productList[index].unit}}</div>-->
								<!--<div class="price">{{item.productList[index].unitPrice | toFixed}}元/{{item.productList[index].unit}}</div>-->
								<!--<div class="totle">{{item.productList[index].totalPrice | toFixed}}</div>-->
							<!--</div>-->
							<div class="list-item" v-for="(good, index) in item.productList.slice(0, 3)" :key="good.id">
								<div class="num">{{index+1}}</div>
								<div class="name">{{good.productName}}</div>
								<div class="spec">{{good.number}}{{good.unit}}</div>
								<div class="price">{{good.unitPrice | toFixed}}/{{good.unit}}</div>
                				<div class="totle">{{good.totalPrice | toFixed}}</div>
							</div>
							<div class="list-item" v-if="item.bidProductId.length > 2" style="margin-top: -10px;">...</div>
						</div>

            <div class="details">
              <div class="item">报价说明：{{item.bidRequire | getSimpleText}}</div>
              <div class="item">货款结算支付方式：{{item.delRequire | getSimpleText}}</div>
              <div class="item">支付方式：{{item.payMode === 'YHZZ'? '银行转账':'商业承兑汇票'}}</div>
              <div class="item" v-if="item.payMode == 'CDHP'">承兑期限：{{item.bankBillTerm ? item.bankBillTerm + '个月' : '无限制'}}</div>
              <div class="item">税率：{{item.taxRate}}%（价格均含税）</div>
              <div class="item">交付时间：{{item.deliveryDate.substring(0,10)}}</div>
            </div>

						<div class="btn-box">
              <div class="btn" :class="[isActive === 'starting'? 'btn1' : 'btn2']" v-if="isActive !== 'ended'">
                <span v-if="isActive === 'notStart'">竞标开始时间：</span>
								<span v-else>竞标结束时间：</span>
								<p v-if="Number(item.day) > 0">{{item.day}}<span>天</span></p>
								<p>{{item.hour}}<span>小时</span></p>
								<p>{{item.minute}}<span>分钟</span></p>
								<p>{{item.second}}<span>秒</span></p>
							</div>
						</div>

						<div class="company">
							<span class="name">{{item.tClientInfo.clientName}}</span>
							<span>{{item.releasetime}}</span>
						</div>
					</div>
				</router-link>
			</div>
    </div>

    <!-- 分页器 -->
    <div class="pager" v-if="page.total!=0">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import NoDataFound from "@/components/NoDataFound.vue";
import { getSelectBiddingList } from '../api';
let that =''
export default {
  created() {
    this.getTenderList();

  },
  components: {
    NoDataFound
  },
   beforeCreate: function () {
        that = this
    },
  filters:{
    toFixed:function(data){
      data=that.formatMoney(data)
      return data
    },
    getSimpleText(html){
      var re1 = new RegExp("<.+?>","g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
      var msg = html.replace(re1,'');//执行替换成空字符
      return msg;
    }
  },
  data() {
    return {
      isActive: 'starting',
      tenderList: [],
      page: {
        currentPage: 1,
        total: 0,
        pageSize: 8,
        pageCount: 0,
      },
      _timeout: null
    };
  },
  methods: {
    // 状态
    tenderStatus(startTime, endTime) {
      startTime = startTime.replace(/-/g, '/');
      endTime = endTime.replace(/-/g, '/');
      if (new Date(startTime) > new Date()) { // 即将开始
        return "notStart";
      }
      if (new Date(startTime) < new Date() && new Date(endTime) > new Date()) {
        return "starting";
      }
      if (new Date(endTime) < new Date()) {
        return "ended";
      }
    },
    // 获取竞标列表
    getTenderList() {
      clearTimeout(this._timeout); // 清除倒计时
      const _this = this;
      getSelectBiddingList({
        pageSize: _this.page.pageSize,
        pageNo: _this.page.currentPage,
        status: _this.isActive
      }).then(function(res) {
        if (res.errno == 0){
          _this.page.total=res.data.total;
          let items = res.data.list;
          items.forEach(function(item, index) {
            if(_this.tenderStatus(item.startTime, item.endTime) === 'notStart') { // 即将开始
              items[index] = Object.assign(item, {isOver: false, status: 'notStart'});
            } else if(_this.tenderStatus(item.startTime, item.endTime) === 'starting') { // 进行中
              items[index] = Object.assign(item, {isOver: false, status: 'starting'});
            } else if(_this.tenderStatus(item.startTime, item.endTime) === 'ended') { // 已结束
              items[index] = Object.assign(item, {isOver: true, status: 'ended'});
            }
                item.bidPrice = _this.formatMoney(item.bidPrice)


          });
          _this.tenderList=items;
          if(_this.isActive !== 'ended') {
            _this.countdown_fun();
          }
        }
      });
    },
  formatMoney(value) {
      //// 金额转换
      if (value !== undefined && value.toString().indexOf('.') !== -1) {
        if (value !== undefined && value.toString().split('.')[1].length == 2) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '亿元');
          } 
          
        else  if ( value !== undefined && value.toString().split('.')[0].length == 8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 7) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==7 ) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==5) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          } 
          
        else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
          /////
        } else if (value !== undefined && value.toString().split('.')[1].length == 1) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '亿元');
          }
          else if (  value !== undefined && value.toString().split('.')[0].length ==8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          }      
          else if ( value !== undefined && value.toString().split('.')[0].length ==7) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          }    
          else if ( value !== undefined && value.toString().split('.')[0].length ==6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          }  
          else if (value !== undefined && value.toString().split('.')[0].length ==5 ) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 3) + '万元');
          } 
          // else if (9 > value !== undefined && value.toString().split('.')[0].length > 4) {
          //   value = ((value / 10000) * 100) / 100;
          //   return (value = value.toString().slice(0, 4) + '万元');
          // } 
          
          else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
        }

        //////////////
      } else if (value !== undefined && value.toString().length > 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '亿元');
      } else if (value !== undefined && value.toString().length == 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 4) + '亿元');
      } else if (value !== undefined && value.toString().length == 8) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 7) + '万元');
      } else if (value !== undefined && value.toString().length == 7) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 6) + '万元');
      } else if (value !== undefined && value.toString().length == 6) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length == 5) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length <= 4) {
        return (value = value + '元');
      }
    },
		// 选择不同状态的tab
    selectStatus (val) {
      this.isActive = val;
      this.page.currentPage = 1;
      this.getTenderList();
    },
		// 触发分页器
    handleCurrentChange(val) {
			this.page.currentPage = val;
      this.getTenderList();
    },
    // 倒计时
    countdown: function(endTime, showDay = false) {
      endTime = endTime.replace(/-/g, '/');
      const end = Date.parse(endTime);
      const now = Date.parse(new Date());
      const msec = end - now;
      let result = {
        isOver: false
      };
      if(msec <= 0) {
        result.isOver = true;
      } else {
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt(msec / 1000 / 60 / 60);
        let m = parseInt(msec / 1000 / 60 % 60);
        let s = parseInt(msec / 1000 % 60);
        result.hour = h > 9 ? h : '0' + h;
        result.minute = m > 9 ? m : '0' + m;
        result.second = s > 9 ? s : '0' + s;

        if (showDay && result.hour > 24) { //开启后大于24小时，显示天数
          result.day = d;
          result.hour = parseInt(msec / 1000 / 60 / 60 % 24);
        }
      }
      return result;
    },
    // 倒计时处理
    countdown_fun() {
      const _this = this;
      let data = JSON.parse(JSON.stringify(_this.tenderList));
      let overLen = 0;

      data.forEach(function(item, index) {
        if (!item.isOver) {
          if(item.status === 'notStart') { // 即将开始
            let result = _this.countdown(item.startTime, true);
            data[index] = Object.assign(item, result);
            if (result.isOver) { //结束处理
              data[index].status = 'starting';
              data[index].isOver = false;
              data.splice(index, 1);
            }
          } else if(item.status === 'starting') { // 进行中
            let result = _this.countdown(item.endTime, true);
            data[index] = Object.assign(item, result);
            if (result.isOver) { //结束处理
              overLen++;
              data[index].status = 'ended';
              data.splice(index, 1);
            }
          } else { // 已结束
            data[index].isOver = true;
            data[index].status = 'ended';
            overLen++;
          }
        } else {
          overLen++;
          data.splice(index, 1);
        }
      });

      _this.tenderList = JSON.parse(JSON.stringify(data));
      if (overLen < _this.tenderList.length) {
        _this._timeout = setTimeout(_this.countdown_fun, 1000);
      }
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    clearTimeout(this._timeout); // 当离开页面时，清除倒计时
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;

  .filter-content {
    .status-tab {
      margin: 17px 0;
      padding: 0 41px;
      box-sizing: border-box;
      background: #fff;

      &-item {
        display: inline-block;
        margin-right: 52px;
        width: 72px;
        text-align: center;
        line-height: 42px;
        font-size: 14px;
        color: #666666;
        cursor: pointer;

        &.active {
          position: relative;
          color: #008cf4;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 2px;
            background: #008cf4;
          }
        }
      }
    }
  }

  .container {
    margin-top: 20px;

    .tender-box {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;

      .tender-item {
        width: calc(50% - 16px);
        background: #fff;
        margin: 0 8px 17px 8px;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
				transition: all 0.4s ease;
				cursor: pointer;

        &:hover {
          box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.1);

          .btn2 {
            color: #008cf4;
            background: #eef9ff;
            border-color: #5ac0ff;
          }
        }
        .header {
          display: flex;
					justify-content: space-between;
          align-items: center;
					height: 55px;
					border-bottom: 1px solid #e8e8e8;
					padding: 0 24px;
					box-sizing: border-box;

          .title {
						display: flex;
          	align-items: center;

						.ranking {
							width: 70px;
							height: 30px;
							text-align: center;
							line-height: 30px;
							border-radius: 2px;
							color: #008cf4;
							font-size: 18px;
							background: #f0f7ff;
						}
						.name {
							width: 298px;
							font-size: 20px;
							color: #333333;
							line-height: 1;
							overflow: hidden;
							white-space: nowrap;
							text-overflow:ellipsis;
							margin-left: 16px;
						}
					}
					.price {
						font-size: 20px;

            &.price1 {
              color: #ff4400;
            }
            &.price2 {
              color: #008cf4;
            }
            &.price3 {
              color: #666666;
            }
					}
        }
				.main {
					padding: 20px 24px;

					.list {
            height: 150px;

						.list-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex: 1;
							color: #333;
							padding: 0 12px;
							margin-bottom: 16px;
							line-height: 1;

							&.title {
                color: #888;
                font-size: 14px;
              }
              .num {
								width: 30px;
								font-size: 16px;
								color: #888;
							}
							.name {
								width: 200px;
								font-size: 16px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow:ellipsis;
							}
							.spec {
								width: 100px;
								font-size: 16px;
							}
							.price {
								width: 130px;
								font-size: 16px;
                overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
							}
              .totle {
                width: 130px;
								font-size: 16px;
                overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
                overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
                overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
              }
						}
					}
          .details {
            /deep/ div.item {
              font-size: 14px;
              color: #666666;
              overflow: hidden;
              white-space: nowrap;
              text-overflow:ellipsis;
              width: 536px;
            }
          }
					.btn-box {
						margin-top: 20px;

						.btn {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 100%;
							height: 42px;
							font-size: 18px;
							border-radius: 4px;
              color: #ffffff;

							p {
                font-size: 16px;
                color: #ffffff;

                span {
                  color: #FFD44D;
                  margin: 0 4px;
                }
              }
              &.btn1 {
								background: linear-gradient(to right, #ff5a48, #ff3d47);
							}
							&.btn2 {
								background: #55c176;
							}
						}
					}
					.company {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 20px;
						color: #888;
						font-size: 16px;
						line-height: 1;

            .name {
              width: 65%;
            }
					}
				}
      }
    }
  }

  .pager {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: right;
  }
}
</style>
