var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c("div", { staticClass: "filter-content" }, [
      _c("div", { staticClass: "status-tab" }, [
        _c(
          "div",
          {
            staticClass: "status-tab-item",
            class: { active: _vm.isActive === "starting" },
            on: {
              click: function ($event) {
                return _vm.selectStatus("starting")
              },
            },
          },
          [_vm._v("正在进行中")]
        ),
        _c(
          "div",
          {
            staticClass: "status-tab-item",
            class: { active: _vm.isActive === "notStart" },
            on: {
              click: function ($event) {
                return _vm.selectStatus("notStart")
              },
            },
          },
          [_vm._v("即将开始")]
        ),
        _c(
          "div",
          {
            staticClass: "status-tab-item",
            class: { active: _vm.isActive === "ended" },
            on: {
              click: function ($event) {
                return _vm.selectStatus("ended")
              },
            },
          },
          [_vm._v("已结束")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.tenderList.length == 0
          ? _c("NoDataFound")
          : _c(
              "div",
              { staticClass: "tender-box" },
              _vm._l(_vm.tenderList, function (item, ind) {
                return _c(
                  "router-link",
                  {
                    key: item.id,
                    staticClass: "tender-item",
                    attrs: {
                      to: "/home/TenderDetails?id=" + item.id,
                      tag: "div",
                    },
                  },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c("div", { staticClass: "title" }, [
                        _c("span", { staticClass: "ranking" }, [
                          _vm._v(
                            "No." + _vm._s(ind >= 9 ? ind + 1 : "0" + (ind + 1))
                          ),
                        ]),
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.bidName)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "price",
                          class: [
                            _vm.isActive === "starting"
                              ? "price1"
                              : _vm.isActive === "notStart"
                              ? "price2"
                              : "price3",
                          ],
                        },
                        [_vm._v(_vm._s(item.bidPrice))]
                      ),
                    ]),
                    _c("div", { staticClass: "main" }, [
                      _c(
                        "div",
                        { staticClass: "list" },
                        [
                          _c("div", { staticClass: "list-item title" }, [
                            _c("div", { staticClass: "num" }),
                            _c("div", { staticClass: "name" }, [
                              _vm._v("产品名称"),
                            ]),
                            _c("div", { staticClass: "spec" }, [
                              _vm._v("采购量"),
                            ]),
                            _c("div", { staticClass: "price" }, [
                              _vm._v("单价"),
                            ]),
                            _c("div", { staticClass: "totle" }, [
                              _vm._v("产品总价"),
                            ]),
                          ]),
                          _vm._l(
                            item.productList.slice(0, 3),
                            function (good, index) {
                              return _c(
                                "div",
                                { key: good.id, staticClass: "list-item" },
                                [
                                  _c("div", { staticClass: "num" }, [
                                    _vm._v(_vm._s(index + 1)),
                                  ]),
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(good.productName)),
                                  ]),
                                  _c("div", { staticClass: "spec" }, [
                                    _vm._v(
                                      _vm._s(good.number) + _vm._s(good.unit)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toFixed")(good.unitPrice)
                                      ) +
                                        "/" +
                                        _vm._s(good.unit)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "totle" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("toFixed")(good.totalPrice))
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          item.bidProductId.length > 2
                            ? _c(
                                "div",
                                {
                                  staticClass: "list-item",
                                  staticStyle: { "margin-top": "-10px" },
                                },
                                [_vm._v("...")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "details" }, [
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "报价说明：" +
                              _vm._s(_vm._f("getSimpleText")(item.bidRequire))
                          ),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "货款结算支付方式：" +
                              _vm._s(_vm._f("getSimpleText")(item.delRequire))
                          ),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "支付方式：" +
                              _vm._s(
                                item.payMode === "YHZZ"
                                  ? "银行转账"
                                  : "商业承兑汇票"
                              )
                          ),
                        ]),
                        item.payMode == "CDHP"
                          ? _c("div", { staticClass: "item" }, [
                              _vm._v(
                                "承兑期限：" +
                                  _vm._s(
                                    item.bankBillTerm
                                      ? item.bankBillTerm + "个月"
                                      : "无限制"
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "税率：" + _vm._s(item.taxRate) + "%（价格均含税）"
                          ),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v(
                            "交付时间：" +
                              _vm._s(item.deliveryDate.substring(0, 10))
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "btn-box" }, [
                        _vm.isActive !== "ended"
                          ? _c(
                              "div",
                              {
                                staticClass: "btn",
                                class: [
                                  _vm.isActive === "starting" ? "btn1" : "btn2",
                                ],
                              },
                              [
                                _vm.isActive === "notStart"
                                  ? _c("span", [_vm._v("竞标开始时间：")])
                                  : _c("span", [_vm._v("竞标结束时间：")]),
                                Number(item.day) > 0
                                  ? _c("p", [
                                      _vm._v(_vm._s(item.day)),
                                      _c("span", [_vm._v("天")]),
                                    ])
                                  : _vm._e(),
                                _c("p", [
                                  _vm._v(_vm._s(item.hour)),
                                  _c("span", [_vm._v("小时")]),
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(item.minute)),
                                  _c("span", [_vm._v("分钟")]),
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(item.second)),
                                  _c("span", [_vm._v("秒")]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "company" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.tClientInfo.clientName)),
                        ]),
                        _c("span", [_vm._v(_vm._s(item.releasetime))]),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
      ],
      1
    ),
    _vm.page.total != 0
      ? _c(
          "div",
          { staticClass: "pager" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next",
                total: _vm.page.total,
                "page-size": _vm.page.pageSize,
                "current-page": _vm.page.currentPage,
              },
              on: { "current-change": _vm.handleCurrentChange },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }