var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: {
        margin: "0 auto",
        width: "100px",
        height: `${_vm.height}px`,
        "padding-top": `${_vm.paddingTop}px`,
        "box-sizing": "border-box",
      },
    },
    [
      _c("img", { attrs: { src: require("@/assets/nodata.png"), alt: "" } }),
      _c("p", { staticStyle: { "text-align": "center", color: "#666" } }, [
        _vm._v("暂无数据"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }